<template>
  <div>
    <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
      <div class="mx-auto px-4 md:px-8">
        <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
          <div class="mt-20">
            <p
              class="text-center text-2xl mb-2 font-bold text-indigo-500 md:text-left"
            >
              Dentures
            </p>

            <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
              Teeth often times break, chip and become decayed. Generally
              damaged and decayed teeth can be restored. Many modern dental
              advancements have expanded what tooth restorations can do.

              <br /><br />A denture is a removable dental device that replaces
              all-natural teeth. Partial dentures cover a small section if only
              a few teeth are missing. If few natural teeth are left, the
              remaining ones may be removed and the whole jaw fitted with
              complete dentures. <br /><br />Dentures can often last for years
              but may need adjustment or repair. We have Removable Partial
              Dentures, Complet Dentures, Partial Dentures with Metal Ceramics,
              Chrom Blended and Acrylic Options, Refixations, and Stainless
              steel Crown Services. Come where you can get your Smile Back.
            </p>
          </div>
          <div class="mt-20 lg:ml-20 ml-10 mb-7">
            <div
              class="relative z-0 w-[15rem] h-[15rem] ml-[-2.5rem] lg:w-[25rem] lg:h-[25rem] bg-[#5c6ab1] flex justify-center rounded-3xl items-center"
            ></div>
            <div
              class="relative inset-0 border-8 border-gray-200 mt-[-14rem] lg:mt-[-23rem] flex bg-white justify-center items-center z-20 w-[15rem] h-[15rem] lg:w-[25rem] lg:h-[25rem] flex justify-center rounded-3xl"
            >
              <img
                src="@/assets/image/pro1.jpeg"
                loading="lazy"
                alt="Photo by Minh Pham"
                class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 rounded-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "about-page",
};
</script>
  
  <style lang="scss" scoped>
</style>
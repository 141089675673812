<template>
  <div>
    <Navbar />
    <Landing />
    <Description/>
    <Detail/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from "@/components/ServiceDetail/Navbar.vue";
import Landing from "@/components/ServiceDetail/Landing.vue";
import Description from "@/components/ServiceDetail/Description.vue"
import Detail from "@/components/ServiceDetail/Detail.vue"
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Navbar,
    Landing,
    Description,
    Detail,
    Footer
  },
};
</script>

<style lang="scss" scoped>
</style>
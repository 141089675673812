<template>
  <div>
    <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
      <div class="mx-auto px-4 md:px-8">
        <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
          <div class="mt-20 lg:ml-20 item-center ml-10 justify-center mb-7">
            <div
              class="relative z-0 w-[12rem] h-[12rem] lg:w-[25rem] lg:h-[25rem] bg-[#5c6ab1] flex item-center justify-center rounded-3xl rotate-45 items-center"
            ></div>
            <div
              class="relative inset-0 border-8 border-gray-200 mt-[-12rem] lg:mt-[-25rem] flex bg-white item-center justify-center items-center z-20 w-[12rem] h-[12rem] lg:w-[25rem] lg:h-[25rem] flex justify-center rounded-3xl"
            >
              <img
                src="@/assets/image/office.jpeg"
                loading="lazy"
                alt=""
                class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 rounded-2xl"
              />
            </div>
          </div>

          <div class="md:pt-8">
            <p class="text-center font-bold text-2xl text-indigo-500 md:text-left">
              About Our Dental Clinic
            </p>

            <h1
              class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left"
            >
              Your Perfect Dental Care Partner!
            </h1>

            <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
              Edna Dental Clinic (EDNA) is one of the best Dental Clinics in
              Addis Ababa as we make an effort to put a perfect smile on your
              face. We aim at providing comprehensive and unparalleled dental
              services at affordable prices prioritizing patient care and
              safety. All services at Edna Dental conform to the highest
              standards of sterilization and hygiene, material and equipment
              quality control, and radiation safety. In addition to providing
              high-quality dental and oral treatment and consulting services,
              our company focuses on quality and expanding social awareness.<br /><br />

              Edna was established on November 2002 by Dr. Getachew Hagos Bahru
              and his colleagues. Our company currently has more than 13 years
              of Experience with 3 full-time dentists, 3 professional nurses, 2
              technicians, 1 general manager, and 1 assistant. Edna is located
              in Mati Building 4th Floor Mimura District 10 in front of CMC Michael Church near Lomyad
              Super Market/Emjoy Burger. Most people are nervous or scared about dental treatment. Visit us and experience totally comfortable and painless dental care with individual attention<br /><br />

             
              
            </p>
            <!-- <div class="mt-8 flex flex-wrap gap-4 text-center">
              <a
                href="#"
                class="block w-full rounded bg-indigo-500 px-12 py-3 text-sm font-medium text-white shadow hover:bg-[#041697] focus:outline-none focus:ring active:bg-[#5c6ab1] sm:w-auto"
              >
                Contact Us
              </a>
              <a
                href="#"
                class="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-[#5c6ab1] shadow hover:[#041697] focus:outline-none focus:ring active:[#041697] sm:w-auto"
              >
                Browse Service
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about-page",
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div>
    <div class="bg-[#5c6ab1] py-6 sm:py-8 lg:py-12">
      <div class="mx-auto px-4 md:px-8">
        <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
          <!-- grid1  -->
          <div class="rounded-3xl">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.465030655174!2d38.834555874713416!3d9.021272891039729!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b9aa1470f6097%3A0xd1a438529ad2d9ba!2sMati%20Building%2C%20Addis%20Ababa!5e0!3m2!1sen!2set!4v1682279025731!5m2!1sen!2set"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <!-- grid 2 -->
          <div class="bg-white rounded-3xl py-6 sm:py-8 lg:py-12">
            <div class="mx-auto px-4 md:px-8">
              <!-- text - start -->
              <div class="mb-10 md:mb-16">
                <h2
                  class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl"
                >
                  Email Us
                </h2>
              </div>

              <form
                class="mx-auto grid max-w-screen-md gap-4 sm:grid-cols-2"
                @submit.prevent="sendEmail"
              >
                <div class="sm:col-span-2">
                  <input
                    v-model="name"
                    name="name"
                    placeholder="Your Name"
                    class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                  />
                </div>
                <div class="sm:col-span-2">
                  <input
                    v-model="email"
                    name="email"
                    placeholder="Email"
                    class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                  />
                </div>
                <!-- <div class="sm:col-span-2">
                  <input
                    v-model="form.subject"
                    placeholder="Subject"
                    class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                  />
                </div> -->

                <div class="sm:col-span-2">
                  <textarea
                    v-model="message"
                    name="message"
                    placeholder="Message"
                    class="h-20 w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                  ></textarea>
                </div>

                <div class="flex items-center justify-between sm:col-span-2">
                  <button
                    class="inline-block w-full rounded-lg bg-indigo-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base"
                  >
                    Send
                  </button>
                </div>
              </form>
              <!-- form - end -->
            </div>
          </div>
        </div>
        <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
          <div class="mb-5 md:mb-5 mt-5">
            <div class="mb-16 md:mb-0 flex items-center">
              <img
                src="@/assets/image/ednalogo.png"
                class="h-8 mr-3"
                alt="FlowBite Logo"
              />
              <span
                class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
                >Edna Dental Clinic</span
              >
            </div>
            <p
              class="mx-auto flex flex-row lg:mt-5 -mt-12 text-start mr-15 text-white md:text-md"
            >
              <svg
                class="h-8 w-8 text-[#041697] mr-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>

              Mati Building 4th Floor <br />In front of CMC Micheal,<br />
              Near Lomyad Super Market / Injoy Burger
            </p>
          </div>
          <div>
            <div class="mb-5 mt-5">
              <div class="mb-16 md:mb-0 flex items-center">
                <span
                  class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
                  >Phone</span
                >
              </div>
              <div
                class="mx-auto text-start -mt-12 lg:mt-1 mr-15 text-white md:text-md"
              >
                <div class="flex flex-row">
                  <svg
                    class="h-6 w-6 mr-1 text-[#041697]"
                    viewBox="0 0 24 24"
                    fill="#041697"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                    />
                  </svg>
                  <p><a :href="`tel:+251911477255`">0911477255</a></p>
                </div>
                <div class="flex flex-row">
                  <!-- <svg class="h-6 w-6 mr-1 text-[#041697]"  viewBox="0 0 24 24"  fill="#041697"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg> -->
                  <p class="ml-7">
                    <a :href="`tel:+251911603056`">0911603056</a>
                  </p>
                </div>
                <div class="flex flex-row">
                  <!-- <svg class="h-6 w-6 mr-1 text-[#041697]"  viewBox="0 0 24 24"  fill="#041697"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg> -->
                  <p class="ml-7">
                    <a :href="`tel:+251116676211`">0116676211</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="mb-5 mt-5">
              <div class="mb-16 md:mb-0 flex items-center">
                <span
                  class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
                  >Email</span
                >
              </div>
              <a class="mailto" href="mailto:info@ednadent.com">
                <p
                  class="mx-auto flex flex-row -mt-12 lg:mt-1 text-start text-white md:text-md"
                >
                  <svg
                    class="h-8 w-8 text-[#041697] mr-2"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                    />
                    <polyline points="22,6 12,13 2,6" />
                  </svg>
                  info@ednadent.com
                </p></a
              >
            </div>

            <div class="mb-5 md:mb-5 mt-5">
              <div class="mb-16 md:mb-0 flex items-center">
                <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
                  <a
                    href="https://www.facebook.com/people/Edna-Dental-Clinic-Addis-Ababa-Mati-bulilding/100066296658959/"
                    class="text-white hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                  <router-link
                    to=""
                    class="text-white hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <!-- <span class="sr-only">Instagram page</span> -->
                  </router-link>
                  <!-- <router-link
                    to="#"
                    class="text-white hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                      />
                    </svg>
                  </router-link> -->

                  <a
                    href="https://www.linkedin.com/in/edna-dental-clinic-204bbb137/?originalSubdomain=et"
                    class="text-white hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"
                      />
                      <rect x="2" y="9" width="4" height="12" />
                      <circle cx="4" cy="4" r="2" />
                    </svg>
                    <!-- <span class="sr-only">Dribbble account</span> -->
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr
          class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"
        />
        <span class="block text-sm text-white sm:text-center">
          <p>
            Copyright &copy; 2023, All Right Reserved | Powerd By
            <button @click="btnClick">
              <p class="text-[#67e8f9] hover:text-[#a5f3fc]">Scitech Valley</p>
            </button>
          </p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'

import emailjs from "emailjs-com";
export default {
  name: "footer-page",
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
  methods: {
    btnClick() {
      window.open("https://www.scitechvalley.com");
    },

    sendEmail(e) {
      try {
        emailjs
          .sendForm(
            "service_qwjfcbc",
            "template_oxwn6mr",
            e.target,
            "z4LBYZNKs5YF8RweB",
            {
              name: this.name,
              email: this.email,
              message: this.message,
            }
          )
          .then(
            this.$toast.success(`Your Message Sucessfully Sent!`, {
              position: "top-right",
            })
          );
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.name = "";
      this.email = "";
      this.message = "";
    },
  },

  //  sendEmail(){
  //   emailjs.sendForm('service_45gsfs8', 'template_tmesubi', this.$refs.form, 'YOUR_PUBLIC_KEY')
  //     .then((result) => {
  //         console.log('SUCCESS!', result.text);
  //     }, (error) => {
  //         console.log('FAILED...', error.text);
  //     });
  // }
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div>
    <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
      <div class="mx-auto px-4 md:px-8">
        <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
          
          <div class="mt-20">
            <!-- <p class="text-center font-bold text-indigo-500 md:text-left">
              Endodontic Treatment
            </p> -->

            <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
              Fillings help patch the armor of your tooth. A tooth is strong for
              a reason. It enables you to eat comfortably and effectively,
              tearing off pieces of food and efficiently grinding it into
              smaller pieces that are easy to swallow and digest. The enamel of
              a tooth is the tough outer layer of what is a delicate,
              complicated living part of your body. Sometimes, due to
              overexposure to sugar and/or acid, the enamel begins to break
              down, becoming thin and weak. Without intervention, this decay
              (cavity) will progress, involving larger areas of the tooth,
              moving deeper into the inner layers and eventually threatening the
              center of the tooth (the nerve).<br /><br />
              Many times, decay remains hidden between your teeth (inter
              proximally) until revealed by x-Rays during your dental exam and
              cleaning. Decay can also begin in the deep ridges in molars or
              hide in areas difficult to reach with a toothbrush. Although this
              damage can sometimes be visible to the naked eye, most decay is
              not readily seen and is revealed only when diagnosed by a good
              dentist.
            </p>
          </div>
          <div class="mt-20 lg:ml-20 ml-10 mb-7">
            <div
              class="relative z-0 w-[15rem] h-[15rem] ml-[-2.5rem] lg:w-[25rem] lg:h-[25rem] bg-[#5c6ab1] flex justify-center rounded-3xl items-center"
            ></div>
            <div
              class="relative inset-0 border-8 border-gray-200 mt-[-14rem] lg:mt-[-23rem] flex bg-white justify-center items-center z-20 w-[15rem] h-[15rem] lg:w-[25rem] lg:h-[25rem] flex justify-center rounded-3xl"
            >
              <img
                src="@/assets/image/endo1.jpeg"
                loading="lazy"
                alt="Photo by Minh Pham"
                class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 rounded-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "about-page",
};
</script>
  
  <style lang="scss" scoped>
</style>
<template>
  <div class="mt-20">
    <section class="flex h-45 flex-col justify-between gap-6 sm:gap-10 md:gap-16 lg:flex-row">
      <!-- content - start -->
      <div class="flex ml-10 mr-10 flex-col justify-center sm:text-center lg:py-12 lg:text-left xl:w-5/12 xl:py-24">
        <p class="mb-4 font-bold text-indigo-700 mt-20 lg:mt-5 md:mb-6 md:text-lg xl:text-3xl">Pediatric Dentistry
        </p>

        <h1 class="text-white mb-8 text-2xl font-bold sm:text-2xl md:mb-12 md:text-2xl">Let’s Keep Your Children's Smile Healthy and Bright! 
        </h1>
        <div class=" flex flex-wrap gap-4 text-center">
          <a :href="`tel:+251911477255`" class="block w-full rounded bg-indigo-500 px-12 py-3 text-sm font-medium text-white shadow hover:bg-[#041697] focus:outline-none focus:ring active:bg-[#5c6ab1] sm:w-auto"
            >
            Call Us
          </a>
          
        </div>

      </div>
      <!-- content - end -->

      <!-- image - start -->
      <div class="h-[23rem] ml-10 mr-10 mt-10 mb-10  rounded-lg shadow-lg  xl:w-5/12">
        <img src="@/assets/image/perd.png" loading="lazy" class="h-[23rem] w-full object-cover rounded-lg object-center" />
      </div>
      <!-- image - end -->
    </section>
  </div>
</template>

<script>
export default {
  name: "landing-page",
};
</script>

<style lang="scss" scoped>
section {
  background-image: linear-gradient(
      rgba(61, 141, 207, 0.5),
      rgba(27, 67, 120, 0.9)
    ),
    
}
</style>
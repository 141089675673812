<template>
  <div>
    <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
      <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
        <!-- text - start -->
        <div class="mb-7 md:mb-5">
          <h2
            class="mb-2 text-center text-2xl text-indigo-500 font-bold text-gray-800 md:mb-3 lg:text-3xl"
          >
          Our Treatment
          </h2>

        </div>
        <!-- text - end -->

        <div class="grid gap-8 sm:grid-cols-2 xl:grid-cols-3 xl:gap-8">
          <!-- feature - start -->
          <div
            class="flex flex-col text-startF bg-white rounded-2xl pt-8 pb-8 pl-4 pr-4"
          >
            

            <h3
              class="mb-2 text-start text-lg text-indigo-500 font-semibold md:text-xl"
            >
              Oral and Maxilofacial Surgery
            </h3>
            <p class="mb-2 text-start text-gray-500">
              We offer minor to complex facial and oral surgeries by our
              experienced and professional doctors who specialize in
              Maxillofacial Surgery.
            </p>
            <router-link
              to="/oralMaxilofacial"
              class="font-bold flex flex-row text-indigo-500 transition duration-100 hover:text-indigo-600 active:text-indigo-700"
              >Read More <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg></router-link
            >
          </div>
          <!-- feature - end -->

          <!-- feature - start -->
          <div
            class="flex flex-col items-start bg-white rounded-2xl pt-8 pb-8 pl-4 pr-4"
          >
            

            <h3
              class="mb-2 text-start text-lg text-indigo-500 font-semibold md:text-xl"
            >
              Endodontics and operative dentistry
            </h3>
            <p class="mb-2 text-start text-gray-500">
              First priority for our community is saving and preserving natural
              dentition through community awareness creation and education.
            </p>
            <router-link
              to="/endodontics"
              class="font-bold flex flex-row text-indigo-500 transition duration-100 hover:text-indigo-600 active:text-indigo-700"
              >Read More <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg></router-link
            >
          </div>
          <!-- feature - end -->

          <!-- feature - start -->
          <div
            class="flex flex-col text-start bg-white rounded-2xl pt-8 pb-8 pl-4 pr-4"
          >
            <h3
              class="mb-2 text-start text-lg text-indigo-500 font-semibold md:text-xl"
            >
              Periodontology
            </h3>
            <p class="mb-2 text-start text-gray-500">
              We are aware of that periodontal structures are the main source of
              teeth strength and in general the health of periodontal tissue is
              as vital as healthy teeth.
            </p>
            <router-link
              to="/periodontology"
              class="font-bold flex flex-row text-indigo-500 transition duration-100 hover:text-indigo-600 active:text-indigo-700"
              >Read More <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg></router-link
            >
          </div>
          <!-- feature - end -->

          <!-- feature - start -->
          <div
            class="flex flex-col text-start bg-white rounded-2xl pt-8 pb-8 pl-4 pr-4"
          >
          

            <h3
              class="mb-2 text-start text-lg text-indigo-500 font-semibold md:text-xl"
            >
              Orthodontics
            </h3>
            <p class="mb-2 text-start text-gray-500">
              We have orthodontics specialists to deal with simple and
              complicated fixed and removable orthodontics cases including the
              new emerging technologies like Invisaline.
            </p>
            <router-link
              to="/orthodontics"
              class="font-bold flex flex-row text-indigo-500 transition duration-100 hover:text-indigo-600 active:text-indigo-700"
              >Read More <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg></router-link
            >
          </div>
          <!-- feature - end -->

          <!-- feature - start -->
          <div
            class="flex flex-col text-start bg-white rounded-2xl pt-8 pb-8 pl-4 pr-4"
          >
            <!-- <div class="mb-6 flex h-12 w-12 items-center rotate-45 justify-center rounded-lg bg-indigo-500 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 -rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </div> -->

            <h3
              class="mb-2 text-starttext-lg text-indigo-500 font-semibold md:text-xl"
            >
              Prosthodontics
            </h3>
            <p class="mb-2 text-start text-gray-500">
              we have above 13 years of experience on prosthodontics that deals
              with fixed and removable partial denture and complete denture.
            </p>
            <router-link
              to="/prosthodontics"
              class="font-bold flex flex-row text-indigo-500 transition duration-100 hover:text-indigo-600 active:text-indigo-700"
              >Read More <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg></router-link
            >
          </div>
          <!-- feature - end -->

          <!-- feature - start -->
          <div
            class="flex flex-col text-start bg-white rounded-2xl pt-8 pb-8 pl-4 pr-4"
          >
            

            <h3
              class="mb-2 text-start text-lg text-indigo-500 font-semibold md:text-xl"
            >
              Pediatric Dentistry
            </h3>
            <p class="mb-2 text-start text-gray-500">
              Our clinic gives special attention to kids. So that we give
              treatment for pediatrics in great care and behavioral treatment.
              One of our special identities is pediatric teeth care.
            </p>
            <router-link
              to="/pediatric"
              class="font-bold flex flex-row text-indigo-500 transition duration-100 hover:text-indigo-600 active:text-indigo-700"
              >Read More <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg></router-link
            >
          </div>
          <!-- feature - end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "service-page",
};
</script>

<style lang="scss" scoped>
</style>
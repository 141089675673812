<template>
  <div>
    <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
      <div class="mx-auto px-4 md:px-8">
        <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
          

          <div class="mt-20">
            <p class="text-center text-2xl mb-2 font-bold text-indigo-500 md:text-left">
              Our Approach to Preventative Care
            </p>

            <!-- <h1
                class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left"
              >
                Our competitive advantage
              </h1> -->

            <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
              In addition to regular exams and cleanings, our dental office uses
              minimally invasive digital X-rays, fluoride treatments, and
              sealants to help prevent dental decay. A proactive plan of
              preventative dental care helps avoid serious and costly dental
              problems and preserves the health and beauty of your smile. Here
              we do scaling and polishing, Gingivectomy, and Wire Splinting.
              <br /><br />

            </p>
          </div>
          <div class="mt-20 lg:ml-20 ml-10 mb-7">
            <div
              class="relative z-0 w-[15rem] h-[15rem] ml-[-2.5rem] lg:w-[25rem] lg:h-[25rem] bg-[#5c6ab1] flex justify-center rounded-3xl items-center"
            ></div>
            <div
              class="relative inset-0 border-8 border-gray-200 mt-[-14rem] lg:mt-[-23rem] flex bg-white justify-center items-center z-20 w-[15rem] h-[15rem] lg:w-[25rem] lg:h-[25rem] flex justify-center rounded-3xl"
            >
              <img
                src="@/assets/image/peri1.jpeg"
                loading="lazy"
                alt="Photo by Minh Pham"
                class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 rounded-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "about-page",
};
</script>
  
  <style lang="scss" scoped>
</style>
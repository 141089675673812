<template>
  <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
    <div class="mx-auto lg:ml-5 lg:mr-5 px-4 md:px-8">
      <div class="grid gap-5 md:grid-cols-2 lg:gap-12">
      <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
        To stop the progression of this destruction of your tooth, the dentist
        will remove all areas of decay and will replace it with a filling. The
        two main types of filling material are amalgam (“silver”) and composite
        (a resin, tooth-colored material). Both are effective in arresting the
        development of decay and the dentist will choose the type of material
        based on your personal needs, the location of the cavity, and
        occasionally insurance requirements. This material helps to regain some
        of the strength lost in the process of decay.

        <br /><br />
        Cavities don’t get smaller if neglected. Your dentist has many tools
        available to keep your mouth healthy and strong if you make and keep the
        appointments that allow him to do so. We also provide GIC filling and
        Temporary Fillings.
      </p>

      <div
        class="relative mb-6 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:mb-8"
      >
        <img
          src="@/assets/image/endo2.jpeg"
          loading="lazy"
          alt="Photo by Minh Pham"
          class="h-[30rem] w-full object-cover object-center"
        />
      </div>
      </div>

      <h2 class="mb-2 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
        Endodontic Treatment
      </h2>

      <p class="text-gray-500 sm:text-lg">
        When the nerve of a tooth “dies”, it requires endodontic treatment (root
        canal). This damage can usually be traced to either trauma or deep
        decay, although occasionally a tooth will need a root canal with no
        apparent cause. The very words “root canal” can be scary. People conjure
        up images of horrifying and painful treatments that leave a patient
        exhausted and overwhelmed. But dentistry has come a long way and, most
        of the time, endodontic treatment is much less uncomfortable than the
        days leading up to the appointment which treats it. Once your tooth
        begins aching, you need to call to make an appointment to see your
        dentist. Many times, a tooth will hurt and then feel better, luring a
        patient into believing their tooth is “back to normal”. This is not
        unusual for a tooth with a “dying” nerve. Additionally, as a nerve dies,
        the infection can set in, becoming very serious, very quickly. Swelling
        of the face is the most dangerous symptom, requiring immediate treatment
        at a dental office. If an infected tooth progresses to this degree,
        antibiotics can and do help alleviate the symptoms. But the dying nerve,
        the cause of the infection, MUST be treated or the tooth will eventually
        reinfect.

        <br /><br />Once endodontic treatment is complete, a permanent filling
        will be placed to seal the crown of the tooth, preventing further
        damage. A crown will also be recommended to strengthen the tooth due to
        the change in its overall structure. “Root canal” treatment can be
        frightening. With a good dentist as your partner, even this most
        daunting of treatments can be completed as a successful portion of your
        overall care. Whether you need a filling, a root canal, or an
        extraction, we can help. To start the process, call us. We provide you
        with Root Canal Treatment for Anterior Teeth, Root Canal Treatment for
        Posterior Teeth, and Crown buildup. <br /><br /><span
          class="text-[#5c6ab1]"
        >
          Whether you need a filling, a root canal, or an extraction, we can
          help. To start the process, call us.</span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail-service",
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div
  class="bg-gray-100 fixed top-0 z-50 bg-white flex justify-between lg:py-2 px-4 sm:justify-around items-center sm:px-1 w-screen">
  <div class="logo sm:-ml-10 mt-2">

    <div class="flex items-start">
      <router-link
      to="/"
      class="text-xl font-bold text-[#6366f1] md:text-2xl hover:text-indigo-400"
      >
      <img src="@/assets/image/ednalogo.png" class="h-[4rem]"/>
    </router-link>
    </div>
  </div>
  <div class="sm:hidden">
    <button @click="toggleNav()">
      <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Menu / Menu_Alt_03">
        <path id="Vector" d="M5 17H13M5 12H19M5 7H13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        </svg>
    </button>
  </div>

  <div class="links mt-2 hidden sm:block">
    
    <ul class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0">
      <router-link to="/"> <li class="text-black hover:text-[#6366f1]">Home</li></router-link>
      <router-link to="/">  <li class="text-black  hover:text-[#6366f1]">About</li></router-link>
      <router-link to="/">  <li class="text-black  hover:text-[#6366f1]">Treatment</li></router-link>
      <router-link to="/">  <li class="text-black  hover:text-[#6366f1]">Gallery</li></router-link>
      <router-link to="/"> <li class="text-black  hover:text-[#6366f1]">Contact Us</li></router-link>
    <li class="text-black  hover:text-[#6366f1]">
      <a :href="`tel:+251911477255`">
      <button class="bg-indigo-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl">
          +251911477255
        </button>
      </a>
    </li>
    
  </ul>
  </div>


  <div class="bg-gray-800/40 fixed top-0 left-0 w-screen h-screen sm:hidden" @click="toggleNav()"
    v-if="showMobileNav"></div>

  <div id="mobile-nav" class="bg-white w-[70vw] pt-4 pb-12 px-2 h-screen shadow-sm fixed right-0 top-0 sm:hidden"
    v-if="showMobileNav">
    <span class="flex justify-between mb-12">
      <p class="font-bold text-gray-800">
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Menu / Menu_Alt_03">
          <path id="Vector" d="M5 17H13M5 12H19M5 7H13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          </svg>
      </p>
      <button @click="toggleNav()">back</button>
    </span>
    <section class="flex flex-col h-full justify-between ">
      <div class="links flex flex-col gap-4">
        <ul class="flex-col mt-8  space-y-4 ml-6 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0">
          <router-link to="/"> <li class="text-black py-1 hover:text-[#6366f1]">Home</li></router-link>
          <router-link to="/">  <li class="text-black  py-1 hover:text-[#6366f1]">About</li></router-link>
          <router-link to="/">  <li class="text-black py-1 hover:text-[#6366f1]">Treatment</li></router-link>
          <router-link to="/">  <li class="text-black py-1 hover:text-[#6366f1]">Gallery</li></router-link>
          <router-link to="/"> <li class="text-black py-1 hover:text-[#6366f1]">Contact Us</li></router-link>
          <li class="text-black  hover:text-[#6366f1]">
            <a :href="`tel:+251911477255`">
            <button class="bg-indigo-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl">
                +251911477255
              </button>
            </a>
          </li>
          
        </ul> 
       
      </div>
    </section>
  </div>

</div>
    <!-- <div class="">
      <div class=" bg-gray-100 w-full">
        <nav
          class="container max-w-full w-full md:w-[100rem] bg-gray-100 fixed top-0 z-50 px-6 py-3 mx-auto md:flex md:justify-between md:items-center"
        >
          <div class="flex items-center justify-between">
            <router-link
              to="/"
              class="text-xl font-bold text-[#6366f1] md:text-2xl hover:text-indigo-400"
              >
              <img
              src="@/assets/image/ednalogo.png"
              class="h-[5rem] mr-3"/>
              
            </router-link>
            <div @click="toggleNav" class="flex md:hidden">
              <button
                type="button"
                class="text-gray-100 hover:text-gray-400 focus:outline-none focus:text-gray-400"
              >
                <svg viewBox="0 0 24 24" class="w-6 h-6 ">
                  <path
                    fill-rule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <ul
            :class="showMenu ? 'flex' : 'hidden'"
            class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0"
          >
            <router-link to="/"> <li class="text-black hover:text-[#6366f1]">Home</li></router-link>
            <router-link to="/">  <li class="text-black  hover:text-[#6366f1]">About</li></router-link>
            <router-link to="/">  <li class="text-black  hover:text-[#6366f1]">Treatment</li></router-link>
            <router-link to="/">  <li class="text-black  hover:text-[#6366f1]">Gallery</li></router-link>
            <router-link to="/"> <li class="text-black  hover:text-[#6366f1]">Contact Us</li></router-link>
            <li class="text-black  hover:text-[#6366f1]">
              <a :href="`tel:+251911477255`">
              <button class="bg-indigo-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl">
                +251911477255
                </button>
              </a>
            </li>
            
          </ul>
        </nav>
      </div>
    </div> -->
  </template>
  
  <script>
  // import { ref } from 'vue';
  export default {
      name:'nav-bar',
    //   setup() {
    //   let showMenu = ref(false);
    //   const toggleNav = () => (showMenu.value = !showMenu.value);
    //   return { showMenu, toggleNav };
    // },
    data(){
      return{
        showMobileNav: false,
      }
    },
    methods:{
      toggleNav() {
    this.showMobileNav = !this.showMobileNav;
  },
    }
  
  };
  </script>
  
  <style lang="scss" scoped>
  #mobile-nav {
    background-image: linear-gradient(
        rgba(125, 181, 227, 0.6),
        rgba(27, 67, 120, 0.9)
      )
  }
  </style>
<template>
  <router-view/>
</template>

<style>
#app {
  background-color: #F2F2F2;
 
}

</style>

<template>
  <div>
    <section class="relative lg:mt-[-6rem] h-[30rem] lg:h-[38rem] bg-fixed bg-center bg-cover bg-no-repeat">
      <div
        class="absolute inset-0 sm:from-white/95 sm:to-white/25 "
      ></div>
      <div
        class="relative mx-auto max-w-screen-xl px-4 py-[7rem] sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8"
      >
        <div class="max-w-xl text-center ">
          <h1 class="text-2xl w-full text-white font-extrabold sm:text-4xl">
            Your Perfect Smile 

            <strong class="font-extrabold text-indigo-200">
              Begins Here!
            </strong>
          </h1>

          <p class="mt-4 max-w-lg text-start text-white sm:text-xl/relaxed">
            We provide our services with the highest integrity and professionalism to your dental care plan for you and your families.
          </p>

          <div class="mt-8 flex flex-wrap gap-4 text-center">
            <a :href="`tel:+251911477255`" class="block w-full rounded bg-indigo-500 px-12 py-3 text-sm font-medium text-white shadow hover:bg-[#041697] focus:outline-none focus:ring active:bg-[#5c6ab1] sm:w-auto"
            >
            Call Us
          </a>
            <!-- <router-link to="#"
              class="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-[#5c6ab1] shadow hover:[#041697] focus:outline-none focus:ring active:[#041697] sm:w-auto"
            >
              Browse Service
            </router-link> -->
          </div>
        </div>
      </div>
    </section>
    <div class="grid grid-cols-3 items-center justify-center mt-10 gap-4 ml-1 mr-1 lg:relative lg:mt-[-4rem] lg:ml-[20rem] lg:mr-[20rem]">

            <!-- feature - start -->
            <div class="flex bg-white pt-4 pb-4 h-[10rem] lg:h-full rounded-lg flex-col items-center">
              <div class="mb-1 flex h-8 w-8 items-center justify-center rounded-lg bg-indigo-500 text-white shadow-lg md:h-10 md:w-10 md:rounded-xl">
                <svg class="h-6 w-6 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
                </svg>
                
              </div>
      
              
              <p class="mb-2 text-center text-lg font-bold ">44,000+</p>
              <h3 class="mb-2 text-center text-md font-semibold text-gray-500 md:text-md">Happy Clients</h3>
            </div>
            <!-- feature - end -->
      
            <!-- feature - start -->
            <div class="flex bg-white pt-4 pb-4 h-[10rem] lg:h-full rounded-lg flex-col items-center">
              <div class="mb-1 flex h-8 w-8 items-center justify-center rounded-lg bg-indigo-500 text-white shadow-lg md:h-10 md:w-10 md:rounded-xl">
                <svg class="h-6 w-6 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"/>
                </svg>
              </div>
              <p class="mb-2 text-center text-lg font-bold ">5+</p>
              <h3 class="mb-2 text-center text-md font-semibold text-gray-500 md:text-md">Doctors and Nurses</h3>
            </div>
            <!-- feature - end -->
            <!-- feature - start -->
            <div class="flex bg-white pt-4 pb-4 h-[10rem] lg:h-full rounded-lg flex-col items-center">
              <div class="mb-1 flex h-8 w-8 items-center justify-center rounded-lg bg-indigo-500 text-white shadow-lg md:h-10 md:w-10 md:rounded-xl">
                <svg class="h-6 w-6 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="3" y1="21" x2="21" y2="21" />  <line x1="9" y1="8" x2="10" y2="8" />  <line x1="9" y1="12" x2="10" y2="12" />  <line x1="9" y1="16" x2="10" y2="16" />  <line x1="14" y1="8" x2="15" y2="8" />  <line x1="14" y1="12" x2="15" y2="12" />  <line x1="14" y1="16" x2="15" y2="16" />  <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" /></svg>
              </div>
              <p class="mb-2 text-center text-lg font-bold ">13+</p>
              <h3 class="mb-2 text-center text-md font-semibold text-gray-500 md:text-md">Years Of Experience</h3>
            </div>
            <!-- feature - end -->
      
        </div>
    
  </div>
</template>

<script>
export default {
  name: "landing-page",
};
</script>

<style lang="scss" scoped>
section {
  background-image: linear-gradient(
      rgba(125, 181, 227, 0.6),
      rgba(27, 67, 120, 0.9)
    ),
    url(@/assets/image/hero-section.jpeg);
}
</style>
<template>
  <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
    <div class="mx-auto lg:ml-10 lg:mr-10 px-4 md:px-8">
      <div class="grid gap-5 md:grid-cols-2 lg:gap-12">
      <div>
      <p class="text-center text-2xl font-bold text-indigo-500 md:text-left">
        Wisdom Teeth Removal
      </p>
      <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
        Third molars, or wisdom teeth, are the four teeth that erupt at the back
        of each jaw, usually in your late teens or early 20s.
        <br /><br />Since most people’s jaws don’t have room for them, they
        often come in crooked and impact with other teeth, where they can crowd,
        infect or cause structural damage. Wisdom teeth extraction is generally
        pretty straightforward, involving the removal of the teeth under local
        or general anesthesia depending on the case. The patient can go home the
        same day and should follow any instructions and take any medications the
        dentist has provided.
      </p>
      </div>
      <div
        class="relative mb-6 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:mb-8"
      >
        <img
          src="@/assets/image/wisdom.jpeg"
          loading="lazy"
          alt="Photo by Minh Pham"
          class="h-[25rem] w-full object-cover object-center"
        />
      </div>
      </div>
      <div class="grid gap-5 md:grid-cols-2 lg:gap-12">
        <div>
      <p class="text-center text-2xl font-bold text-indigo-500 md:text-left">
        Root Canal
      </p>
      <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
        A root canal procedure can save the structure of a tooth that has been
        infected or has decayed at the root. The nerve inside the tooth is
        removed. Your canals are cleaned thoroughly and then filled with a
        special material to reinforce the tooth and prevent further infection
        and/or pain.
      </p>
      </div>
      <div
        class="relative mb-6 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:mb-8"
      >
        <img
          src="@/assets/image/root.jpeg"
          loading="lazy"
          alt="Photo by Minh Pham"
          class="h-[25rem] w-full object-cover object-center"
        />
      </div>
      </div>
      <div class="grid gap-5 md:grid-cols-2 lg:gap-12">
        <div>
      <p class="text-center text-2xl font-bold text-indigo-500 md:text-left">
        Extractions
      </p>
      <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
        A tooth extraction may be necessary when a tooth is diagnosed as
        “‘non-restorable”. An extraction can be part of normal, healthy dental
        and/ or orthodontic treatment. This can be the result of several
        different conditions, including extreme decay, infection, or advanced
        periodontal (gum) disease. An extraction may also be necessary for
        orthodontic treatment. Many times a tooth may need to be extracted as
        part of your Orthodontic regimen. Additionally, deciduous (baby teeth)
        may need to be removed to allow for the proper eruption of permanent
        teeth.

        <br /><br />When these conditions require the removal of a tooth, there
        are sedation options available to make the procedure itself as
        comfortable as possible. Contact us today to schedule an appointment.
      </p>
      </div>
      <div
        class="justify-center item-center object-center mb-6 h-[30rem] w-full overflow-hidden rounded-lg bg-gray-100 shadow-lg md:mb-8"
      >
        <img
          src="@/assets/image/extraction.jpeg"
          loading="lazy"
          alt="Photo by Minh Pham"
          class="h-[25rem] w-full object-cover object-center"
        />
      </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "detail-service",
};
</script>

<style lang="scss" scoped>
</style>
import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import ServiceDetail from '../views/ServiceDetail'
import OralMaxilofacial from '../views/OralMaxilofacial'

import Endodontics from '../views/Endodontics'
import Orthodontics from '../views/Orthodontics'
import Pediatric from '../views/Pediatric'
import Periodontology from '../views/Periodontology'
import Prosthodontics from '../views/Prosthodontics'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/serviceDetail',
    name: 'ServiceDetail',
    component: ServiceDetail
  },
  {
    path: '/oralMaxilofacial',
    name: 'OralMaxilofacial',
    component: OralMaxilofacial
  },
  {
    path: '/endodontics',
    name: 'Endodontics',
    component: Endodontics
  },
  {
    path: '/orthodontics',
    name: 'Orthodontics',
    component: Orthodontics
  },
  {
    path: '/pediatric',
    name: 'Pediatric',
    component: Pediatric
  },
  {
    path: '/periodontology',
    name: 'Periodontology',
    component: Periodontology
  },
  {
    path: '/prosthodontics',
    name: 'Prosthodontics',
    component: Prosthodontics
  },
  {
    path: '/scitechValley',
    beforeEnter() {location.href = 'https://www.scitechvalley.com'}
  },

 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

<template>
    <div>
      <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
        <div class="mx-auto px-4 md:px-8">
          <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
            
  
            <div class="mt-20">
              
  
              <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
                Our doctors and staff are committed to delivering exceptional services while maintaining a personalized approach. We would like to become your family’s lifelong partners and assist your children in their continuous journey toward ultimate oral health.
<br><br>When you see your child smiling, your heart melts. Just like any loving parent, you wish to always keep this beautiful smile healthy and bright, and that’s where our caring team comes in.
Pediatric Dentistry and Orthodontics of New York offers comprehensive dental services for children of all ages, from infants to teenagers. We specialize in pain-free treatment, that’s why if you entrust your child to us, they will get the necessary dental care with less anxiety and fear.

              </p>
            
            </div>
            <div class="mt-20 lg:ml-20 ml-10 mb-7">
              <div
                class="relative z-0 w-[15rem] h-[15rem] ml-[-2.5rem] lg:w-[25rem] lg:h-[25rem] bg-[#5c6ab1] flex justify-center rounded-3xl items-center"
              ></div>
              <div
                class="relative inset-0 border-8 border-gray-200 mt-[-14rem] lg:mt-[-23rem] flex bg-white justify-center items-center z-20 w-[15rem] h-[15rem] lg:w-[25rem] lg:h-[25rem] flex justify-center rounded-3xl"
              >
                <img
                  src="@/assets/image/child.jpeg"
                  loading="lazy"
                  alt="Photo by Minh Pham"
                  class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 rounded-2xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "about-page",
  };
  </script>
  
  <style lang="scss" scoped>
  </style>
<template>
    <div class="mt-20">
      <section class="flex  flex-col justify-between gap-6 sm:gap-10 md:gap-16 lg:flex-row">
        <!-- content - start -->
        <div class="flex ml-10 mr-10 flex-col justify-center sm:text-center lg:py-12 lg:text-left xl:w-5/12 xl:py-24">
          <p class="mb-4 font-semibold text-indigo-500 md:mb-6 md:text-lg xl:text-xl">Very proud to introduce</p>
  
          <h1 class="text-black-800 mb-8 text-4xl font-bold sm:text-5xl md:mb-12 md:text-6xl">Revolutionary way to build the web</h1>
  
          <p class="mb-8 leading-relaxed text-white md:mb-12 lg:w-4/5 xl:text-lg">This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text but is random.</p>
  
        </div>
        <!-- content - end -->
  
        <!-- image - start -->
        <div class="h-48 ml-10 mr-10 mt-10 mb-10 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:h-auto xl:w-5/12">
          <img src="@/assets/image/landingser.jpeg" loading="lazy" alt="Photo by Fakurian Design" class="h-full w-full object-cover object-center" />
        </div>
        <!-- image - end -->
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "landing-page",
  };
  </script>
  
  <style lang="scss" scoped>
  section {
    background-image: linear-gradient(
        rgba(61, 141, 207, 0.5),
        rgba(27, 67, 120, 0.9)
      ),
      
  }
  </style>
<template>
    <div>
        <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
            <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
                <div class="mb-10 md:mb-16">
                    <h2 class="mb-4 text-center text-2xl font-bold text-[#5c6ab1] md:mb-6 lg:text-3xl">Our Gallary</h2>
              
                    <p class="mx-auto max-w-screen-md text-center text-gray-500 md:text-sm">Here are some of our works and Testimonials. Here you can check the before and after treatment pictures of our clients. </p>
                  </div>
              <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">
                <!-- image - start -->
                <div class="group border-8 border-[#5c6ab1] relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                  <img src="@/assets/image/gallery4.jpg" loading="lazy" alt="Photo by Minh Pham" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
          
                  <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
          
                  <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Before and After</span>
                </div>
                
                <div class="group border-8 border-[#5c6ab1] relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80">
                  <img src="@/assets/image/gall2.jpg" loading="lazy" alt="Photo by Magicle" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
          
                  <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
          
                  <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Before and After</span>
                </div>
                <!-- image - end -->
          
                <!-- image - start -->
                <div class="group border-8 border-[#5c6ab1] relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80">
                  <img src="@/assets/image/gall1.jpg" loading="lazy" alt="Photo by Martin Sanchez" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
          
                  <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
          
                  <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Before and After</span>
                </div>
                <!-- image - end -->
          
                <!-- image - start -->
                <div class="group border-8 border-[#5c6ab1] relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                  <img src="@/assets/image/gallery3.jpg" loading="lazy" alt="Photo by Lorenzo Herrera" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
          
                  <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
          
                  <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Before and After</span>
                </div>
                <!-- image - end -->
              </div>
            </div>
          </div>

    </div>
</template>

<script>
    export default {
        name:'gallery-page'
        
    }
</script>

<style lang="scss" scoped>

</style>
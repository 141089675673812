<template>
    <div>
        <Landing/>
        <Navbar/>
        <Description/>
        <!-- <Detail/> -->
        <Footer/>

    </div>
</template>

<script>
import Landing from '@/components/orthodontics/Landing.vue'
import Navbar from '@/components/ServiceDetail/Navbar.vue'
import Description from '@/components/orthodontics/Description.vue';
// import Detail from '@/components/orthodontics/Detail.vue';
import Footer from '@/components/Footer.vue';
    export default {
        name:'pro-sthe',
        components:{
            Landing,
            Navbar,
            Description,
            // Detail,
            Footer
        }
        
    }
</script>

<style lang="scss" scoped>

</style>
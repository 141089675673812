<template>
  <div>
    <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
      <div class="mx-auto px-4 md:px-8">
        <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
          

          <div class="mt-20 ml-10 mb-7">
            <!-- <p
              class="text-center text-2xl font-bold text-indigo-500 md:text-left"
            >
              Oral Surgery
            </p> -->

            <h1
              class="mb-4 text-center text-2xl mb-2 font-bold text-indigo-500 sm:text-3xl md:mb-6 md:text-left"
            >
            Oral Surgery
            </h1>

            <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
              Our dentists can diagnose and treat a wide variety of conditions.
              This page lists some of the many conditions, treatments, and
              procedures we perform on a regular basis. We diagnose and treat
              patients with diseases affecting the mouth, jaws, face, and neck.
              We work with patients of all ages in a field where we treat
              someone’s face which can make a huge difference to their quality
              of life. We Provide Wound Care Suturing, Milk tooth Extraction,
              Permanent Tooth Extraction, Abscess Drainage, Impacted Tooth
              Extraction, Open Disimpaction, Epulis Removal, Apecectomy,
              Frenectomy, and Inter Maxillary Fixations.
            </p>
          </div>
          <div class="mt-20 lg:ml-20 ml-10 mb-7">
            <div
              class="relative z-0 w-[15rem] h-[15rem] ml-[-2.5rem] lg:w-[25rem] lg:h-[25rem] bg-[#5c6ab1] flex justify-center rounded-3xl items-center"
            ></div>
            <div
              class="relative inset-0 border-8 border-gray-200 mt-[-14rem] lg:mt-[-23rem] flex bg-white justify-center items-center z-20 w-[15rem] h-[15rem] lg:w-[25rem] lg:h-[25rem] flex justify-center rounded-3xl"
            >
              <img
                src="@/assets/image/oral2.jpeg"
                loading="lazy"
                alt="Photo by Minh Pham"
                class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 rounded-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "about-page",
};
</script>
  
  <style lang="scss" scoped>
</style>
<template>
  <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
    <div class="mx-auto ml-10 mr-10 px-4 md:px-8">
      <h2 class="mb-2 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
        Digital X-Rays
      </h2>
      <p class="text-gray-500 sm:text-lg">
        Digital x-rays differ from conventional x-rays by sending image data
        straight to a computer instead of using traditional X-ray film. It can
        be viewed on screen while the sensor is still running, meaning that the
        dental staff can zoom in and rotate the image to help find signs of
        problems more easily.
        <br /><br />And although conventional X-rays already contain a very low
        dose of radiation, digital X-rays contain even less. Digital X-rays are
        an essential first step to creating a good preventative plan of care.
      </p>

      <h2 class="mb-2 mt-3 text-lg font-semibold text-gray-900 dark:text-white">
        Digital X-Rays might reveal:
      </h2>
      <ul
        class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400"
      >
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Cysts or abscesses
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Bone loss
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Tumors
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Bad tooth and root positions
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Developmental abnormalities
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Decay between teeth
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Otherwise invisible problems, such as the interior of the tooth, or
          below the gum line
        </li>
      </ul>

      <!-- <div
        class="relative mb-6 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:mb-8"
      >
        <img
          src="@/assets/image/imagesdes.jpeg"
          loading="lazy"
          alt="Photo by Minh Pham"
          class="h-full w-full object-cover object-center"
        />
      </div> -->

      <h2
        class="mb-2 mt-3 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4"
      >
        Fluoride
      </h2>

      <p class="text-gray-500 sm:text-lg">
        Fluoride is a mineral found in food and water that helps prevent tooth
        decay. Some people don’t get enough fluoride from daily sources, and
        your dentist may recommend professional or in-home fluoride treatments,
        especially for these reasons:
      </p>
      <ul
        class="max-w-md mt-3 space-y-1 text-gray-500 list-inside dark:text-gray-400"
      >
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Deeper than usual pits and fissures on chewing surfaces
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Exposed roots
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Less-than-good oral hygiene habits
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          High carbohydrate or sugar intake
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Recent tooth decay
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Too little fluoride in food and water
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Too little saliva flow
        </li>
        <li class="flex items-center">
          <svg
            class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Too little saliva flow Dry mouth due to aging or certain medications
        </li>
      </ul>
      <p class="text-gray-500 mt-3 sm:text-lg">
        Fluoride treatment alone will not prevent tooth decay. Remember to brush
        twice a day, floss regularly, cut down on sugar, and go to the dentist
        twice a year.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail-service",
};
</script>

<style lang="scss" scoped>
</style>
<template>
    <div>
      <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
        <div class="mx-auto px-4 md:px-8">
          <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
           
  
            <div class="mt-20">
             
              <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
                Teeth often times break, chip and become decayed. Generally damaged and decayed teeth can be restored. Many modern dental advancements have expanded what tooth restorations can do. 

              </p>
              <h1
                class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left"
              >
                Bridges
                
              </h1>
              <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
                A dental bridge is like a partial denture because it covers a small gap, but it is fixed, not removable, and anchored into the teeth on either side of the gap.
                The anchor teeth are called abutments and have to be modified to act as supports. In some cases, dental implants can act as abutments.
                As with other dental restoration methods, bridges restore proper chewing and function, keeping your remaining teeth from drifting, preserving the facial shape and restoring your smile.
                We provide fixed orthodontics or plastic retainers for bringing back your smile. Call us or visit our Clinic for more information. 
              </p>
            
            </div>
            <div class="mt-20 lg:ml-20 ml-10 mb-7">
              <div
                class="relative z-0 w-[15rem] h-[15rem] ml-[-2.5rem] lg:w-[25rem] lg:h-[25rem] bg-[#5c6ab1] flex justify-center rounded-3xl items-center"
              ></div>
              <div
                class="relative inset-0 border-8 border-gray-200 mt-[-14rem] lg:mt-[-23rem] flex bg-white justify-center items-center z-20 w-[15rem] h-[15rem] lg:w-[25rem] lg:h-[25rem] flex justify-center rounded-3xl"
              >
                <img
                  src="@/assets/image/ortho1.jpeg"
                  loading="lazy"
                  alt="Photo by Minh Pham"
                  class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 rounded-2xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "about-page",
  };
  </script>
  
  <style lang="scss" scoped>
  </style>
<template>
    <div>
       

          <div class="bg-gray-100 py-6 sm:py-8 lg:py-12">
            <div class="mx-auto px-4 md:px-8">
              <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
                <div class="mt-5">
                    <div class="mb-10 md:mb-16">
                        <h2
                          class="mb-4 text-start text-2xl text-indigo-500 font-bold text-gray-800 md:mb-6 lg:text-3xl"
                        >
                        Why Edna Dental Clinic
                        </h2>
              
                        <p
                          class="mx-auto max-w-screen-md text-start text-gray-500 md:text-lg"
                        >
                          Our clinic is uniquely known for providing quality services at a
                          fair and competitive price In addition to providing comprehensive
                          family dental care, our clinic is specially designed and structured
                          to provide state-of-the-art technology in key areas of contemporary
                          advances like dental implants and cosmetic dentistry. Edna dental
                          care is unique in terms of ambiance and world-class treatments.
                        </p>
                      </div>
                </div>
                <div class="mt-5 lg:ml-20 ml-10 mb-7">
                 
                  <div
                    class="relative inset-0 border-8 border-indigo-400 flex bg-white justify-center items-center z-20 w-[15rem] h-[15rem] lg:w-[25rem] lg:h-[25rem] flex justify-center rounded-3xl"
                  >
                    <img
                      src="@/assets/image/ednadental.jpeg"
                      loading="lazy"
                      alt=""
                      class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 rounded-2xl"
                    />
                  </div>
                </div>
      
                
              </div>
            </div>
          </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>